//
// Main
//

// Body
body {
  background-color: $page-bg;
}

// Font color from Content background color
.text-page-bg {
  color: $page-bg;
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Containers
  .container,
  .container-fluid {
    padding: 0 get($content-spacing, desktop);
  }

  // Wrapper
  .wrapper {
    padding-left: get($aside-config, width);

    // Fixed header mode
    .header-fixed & {
      padding-top: get($header-config, height, desktop);
    }

    // Fixed header & fixed toolbar modes
    .header-fixed.toolbar-fixed & {
      padding-top: get($header-config, height, desktop) + get($toolbar-config, height);
    }

    // Sticky toolbar mode
    .toolbar-fixed:not(.header-fixed)[data-kt-sticky-toolbar='on'] & {
      padding-top: get($toolbar-config, height);
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  // Containers
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($content-spacing, tablet-and-mobile);
  }

  // Wrapper
  .wrapper {
    // Fixed header mode
    .header-tablet-and-mobile-fixed & {
      padding-top: get($header-config, height, tablet-and-mobile);
    }
  }
}
