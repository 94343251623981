//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Bootstrap base colors
$white: #ffffff;
$gray-100: if(isDarkMode(), #1b1b29, #f8f6f2);
$gray-200: if(isDarkMode(), #2b2b40, #f6f1e9);
$gray-300: if(isDarkMode(), #323248, #dad3c3);
$gray-400: if(isDarkMode(), #474761, #b5b0a1);
$gray-500: if(isDarkMode(), #565674, #968e7e);
$gray-600: if(isDarkMode(), #6d6d80, #8e887a);
$gray-700: if(isDarkMode(), #92929f, #716d66);
$gray-800: if(isDarkMode(), #cdcdde, #403d38);
$gray-900: if(isDarkMode(), #ffffff, #2f344a);

// Theme colors
// Primary
$primary: if(isDarkMode(), #00b2ff, #1565C0);
$primary-active: if(isDarkMode(), #4ac9ff, #003C8F);
$primary-light: if(isDarkMode(), #2d3749, #5E92F3);
$primary-inverse: #ffffff;

// Success
$success: if(isDarkMode(), #50cd89, #50b91f);
$success-active: if(isDarkMode(), #82f3b6, #9fbe22);
$success-light: if(isDarkMode(), #2d3e35, #f4fbdb);
$success-inverse: #ffffff;

// Info
$info: if(isDarkMode(), #9a68ff, #4f55da);
$info-active: if(isDarkMode(), #b48fff, #383ebc);
$info-light: if(isDarkMode(), #382f59, #eff0ff);
$info-inverse: #ffffff;

// Danger
$danger: if(isDarkMode(), #e11d64, #f06445);
$danger-active: if(isDarkMode(), #fe498a, #db5437);
$danger-light: if(isDarkMode(), #452e36, #fff0ed);
$danger-inverse: #ffffff;

// Warning
$warning: if(isDarkMode(), #e7bb22, #e8c444);
$warning-active: if(isDarkMode(), #fdd33f, #cfab2a);
$warning-light: if(isDarkMode(), #3f3a2b, #fdf4d6);
$warning-inverse: #ffffff;

// Border radiues
$border-radius: 0.95rem;
$border-radius-sm: 0.475rem;

// Body
$body-bg: if(isDarkMode(), #1e1e2d, $white);

// Border
$border-dashed-color: if(isDarkMode(), $gray-300, #eaeaea); // Custom variable
$border-color: if(isDarkMode(), $gray-200, #eaeaea); // Custom variable

// Card
$card-border-color: if(isDarkMode(), $gray-300, #e8e5dd);
$card-box-shadow: none;
$card-border-enabled: true;
$card-border-style: dashed;
$card-border-radius: $border-radius;
$card-bg: if(isDarkMode(), $body-bg, rgba(#f8f6f2, 0.3));

// Accordion
$accordion-border-radius: $border-radius;

// Button
$btn-border-radius-sm: $border-radius;

// Accordion
$accordion-border-radius: $card-border-radius;

// Modals
$modal-content-border-radius: $card-border-radius;

// Forms
$input-border-color: if(isDarkMode(), $gray-300, #efe9e1);

// Badge
$badge-border-radius: $border-radius-sm;

// Form
$input-placeholder-color: if(isDarkMode(), $gray-500, $gray-400);
$input-btn-font-size: 1rem;
