.Spreadsheet__cell--readonly {
  color: #2f344a !important;
}

.Spreadsheet__data-viewer {
  white-space: pre;
}

.Spreadsheet__cell {
  text-align: center !important;
  padding-inline: 5px;
}

.spreadsheet-cell-editable {
  background-color: #fcff96;
  max-width: 90px;
}

.spreadsheet-cell-editable-correct {
  background-color: #82f384;
}

.spreadsheet-cell-editable-invalid {
  background-color: #f64e60;
}

.spreadsheet-container {
  position: relative;
}
