.tables-table-cell {
  min-width: 80px;
  max-width: 150px;
  background-color: #e2e6ea;
  color: #001f4f;
  border-width: 1px;
  font-size: 1.1rem;
  padding: 3px 7px;
  border-color: #fdfcfa;
  text-align: center;
  user-select: none;
}

.tables-table-row-active {
  .tables-table-cell {
    background-color: #fdfcfa;
  }
}

.tables-table {
  border-radius: 10px;
  border-collapse: collapse;
  overflow: hidden;
}

.tables-table-header {
  font-weight: 600;
  font-size: 1.1rem;
  background-color: #d9dcdf;
}

.tables-ui {
  bottom: 10px;
  left: 50%;
  position: absolute;
  z-index: 99;
  transform: translateX(-50%);
  background-color: #feffdf;
  padding: 0.6rem 1rem;
  border: 1px solid #eaeaea;
  border-radius: 1rem;

  &:hover {
    opacity: 1 !important;
  }
}

.btn-tables {
  background-color: #feffdf;
  border: 1px solid #97790d !important;
  color: #97790d;
  font-weight: 700;
  margin: 0 0.2rem;
  padding: 0.4rem 0.8rem !important;

  &:hover {
    background-color: #97790d !important;
    color: white;
  }
}

.gradient-1 {
  background-color: #6fb376;
}
.gradient-2 {
  background-color: #98ca73;
}
.gradient-3 {
  background-color: #a7d075;
}
.gradient-4 {
  background-color: #c1d775;
}
.gradient-5 {
  background-color: #d2db77;
}
.gradient-6 {
  background-color: #e0e077;
}
.gradient-7 {
  background-color: #f0e379;
}
.gradient-8 {
  background-color: #f6e678;
}
.gradient-9 {
  background-color: #fedb76;
}
.gradient-10 {
  background-color: #fcc872;
}
.gradient-11 {
  background-color: #fcab6d;
}
.gradient-12 {
  background-color: #f88868;
}
.gradient-13 {
  background-color: #f97664;
}
.gradient-14 {
  background-color: #f77062;
}
.gradient-15 {
  background-color: #f76f63;
}
.gradient-16 {
  background-color: #f65e61;
}
