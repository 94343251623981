.pic-picker-upvote-btn {
  background-color: white;

  &:hover {
    background-color: #5e92f3;
    color: white;
  }
}

.pic-picker-upvote-btn-selected {
  background-color: #5e92f3;
  color: white;
}

.pic-picker-correct {
  background-color: #82f384;
}

.pic-picker-incorrect {
  background-color: #f64e60;
}

