.resize-slider {
  // position: absolute;
  // top: 45%;
  // width: 4px;
  // height: 100px;
  // right: -6px;
  // margin-top: -11px;
  // margin-left: -5px;
  z-index: 10;
  margin-left: 6px;
  margin-right: 6px;
  min-width: 9px;
  background-color: #dfddd7; // navbar gray-2

  &:hover {
    cursor: ew-resize;
  }
}

.resize-slider-circle {
  width: 5px;
  height: 5px;
  border-radius: 3px;
  margin-bottom: 4px;
  background-color: #70757d;
}

.handbook-header {
  display: flex;
  flex-direction: row;
}

.chapter-completed-check {
  margin-left: 25px;
  margin-right: 15px;
}

.header-chapter-crumb {
  height: 5px;
  flex-grow: 1;
  border-radius: 3px;
  margin-left: 2px;
  margin-right: 2px;
}

.left-section {
}

.sidebar {
  background-color: #f8f6f2; // gray-100
}

.course-canvas {
  background-color: #fdfcfa; // custom, close to white
}

.sidebar-header {
  padding: 10px;
  border-bottom: 1px solid #eaeaea !important;
  background-color: white;
}

.my-menu-link {
  padding-inline: 1rem;
  padding-block: 0.5rem;
  margin-block: 2px;
  width: 100%;
  border-radius: 1rem;
  font-weight: 500;
  color: #2f344a;
  border: 0.5px solid #f5f5f5;
  background: white;
  &:hover {
    background-color: #5e92f3;
    color: white;
    cursor: pointer;
    font-weight: 600;
  }

  &.active {
    background-color: #5e92f3;
    color: white;
    cursor: pointer;
    font-weight: 600;
  }

  &:disabled {
    cursor: default;
    background-color: white;
    color: #2f344a;
    opacity: 0.2;
  }
}

// .highlight-effect {
//   z-index: 9999;
//   width: 150px;
//   height: 150px;
//   // border:
//   border-color: #f64e60;
//   border-radius: 50%;
//   border-style: dotted solid;
//   border-width: 3px;
//   position: absolute;
//   // margin: auto;
//   animation-name: hlcirclescale;
//   animation-duration: 5s;
//   animation-timing-function: ease-out;
//   animation-iteration-count: 1;
// }

.highlight-effect-img {
  z-index: 9999;
  // border:
  // border-left: 10px dotted #f64e60;
  // border-right: 10px dotted #f64e60;
  // overflow: hidden;
  // border-radius: 50%;
  position: absolute;
  // margin: auto;
  animation-name: hlcirclescale;
  animation-duration: 4.5s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  // opacity: 0;
}

.highlighter-capture-container {
  position: absolute;
  z-index: 9999;
  border: 3px solid #f64e60;
  cursor: crosshair;
  border-radius: 10px;
  width: 100%;
  height: 100%;
}

.highlight-container {
  position: relative;
}

@keyframes hlcirclescale {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0;
  }
  10% {
    transform: scale(1.3) rotate(180deg);
    opacity: 1;
  }
  40% {
    transform: scale(1.0);
  }
  // 60% {
  //   transform: rotate(-780deg);
  // }
  90% {
    // transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(-1080deg);
    opacity: 0;
  }
}

@keyframes hlcircle {
  0% {
    transform: scale(1) rotate(0deg);
    opacity: 0;
  }
  10% {
    // transform: rotate(360deg);
    opacity: 1;
  }
  // 40% {
  //   transform: scale(1.5) rotate(-360deg);
  //   opacity: 1;
  // }
  // 60% {
  //   transform: rotate(-780deg);
  // }
  90% {
    opacity: 1;
  }
  100% {
    transform: scale(1) rotate(-2520deg);
    opacity: 0;
  }
}
