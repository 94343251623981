.stickies-color-picker {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  margin-right: 5px;
  padding: 2px;

  background-clip: content-box !important;

  &:hover {
    cursor: pointer;
  }
}

.stickies-color-picker-selected {
  border: 2px solid #5f91f3;
}

.stickies-green {
  background: #a3df93;
}

.stickies-orange {
  background: #ffca94;
}

.stickies-violet {
  background: #dfa4dc;
}

.stickies-blue {
  background: #8fb1df;
}


.stickies-ui-part {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 99;

  background-color: white;

  transform: translateX(-50%);
  padding: 0.6rem 1rem;
  border: 1px solid #eaeaea;
  border-radius: 1rem;
}

.stickies-btn-part {
  border: 1px solid #f5f5f5 !important;
  background-color: #5e92f3 !important;
  color: white !important;
  font-weight: 700;
  margin: 0 0.2rem;
  padding: 0.4rem 0.8rem !important;

  &:hover {
    background-color: #1565C0 !important;
    color: white !important;
  }
}
