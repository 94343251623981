.controls-ui {
  position: absolute;
  align-items: center;

  bottom: -75px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;

  height: 110px;
  
  border-radius: 15px 15px 0 0;
  border: 1px solid #eaeaea;
  background-color: #feffdf;

  font-weight: 600;
  font-size: 1rem;
  text-transform: uppercase;

  transition: all 0.4s ease-in-out;

  &:hover {
    bottom: 0px;
  }
}

.controls-ui-tag {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: 35px;

  background-color: #feffdf;
  color: #70757d;

  border-radius: 15px 15px 0 0;
  border-bottom: 1px solid #eaeaea;
}

.btn-canvas-controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #feffdf;
  border: 1px solid #97790d !important;
  color: #97790d;
  font-weight: 700;
  margin: 0 0.2rem;

  min-width: 68px;
  max-width: 68px;

  padding: 0.4rem 0.8rem !important;

  &:hover {
    background-color: #785f07 !important;
    color: white;
  }
}

.btn-canvas-controls-primary {
  background-color: #97790d;
  color: white;

  &:hover {
    background-color: #785f07 !important;
  }
}
