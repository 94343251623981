.ring-container {
  position: relative;
}

.circle {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: -3px;
  left: 4px;
}

.ringring {
  border: 2px solid;
  -webkit-border-radius: 30px;
  border-radius: 30px;
  height: 10px;
  width: 10px;
  position: absolute;
  top: -5px;
  left: 2px;
  -webkit-animation: pulsate 1.5s ease-out;
  animation: pulsate 1.5s ease-out;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  opacity: 0;
}
@-webkit-keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
@keyframes pulsate {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    opacity: 0;
  }
}

.dropdown-toggle::after {
  display: none !important;
}

$gray-1: #fffdf6;
$gray-2: #dfddd7;
$gray-3: #9f9e9a;

.navbar-bar {
  background-color: $gray-1;
  border-bottom: 1px solid #eaeaea;
}

.btn-navbar {
  background-color: $gray-2 !important;
  border-radius: 0.7rem !important;
  color: #716d66 !important;

  &.highlighter-active {
    background-color: #5F91F3 !important;
    color: #eaeaea !important;
  }

  &:hover {
    background-color: $gray-3 !important;
    color: #eaeaea !important;
    // background: #5e92f3 !important;
    // border-color: #5e92f3 !important;
  }

  &.btn.btn-primary:hover:not(.btn-active) {
    background-color: $gray-3 !important;
    color: #eaeaea !important;
  }

  &.btn.btn-primary:active:not(.btn-active) {
    background-color: $gray-3 !important;
    color: #eaeaea !important;
  }

  &.btn.btn-primary:focus:not(.btn-active) {
    background-color: $gray-3 !important;
    color: #eaeaea !important;
  }

  &.btn.btn-primary {
    background-color: $gray-2 !important;
  }
}

.btn-navbar-logout {
  // background-color: $gray-2 !important;
  border-radius: 0.7rem !important;
  color: #716d66 !important;

  &:hover {
    background-color: $gray-3 !important;
    color: #5E92F3 !important;
    // color: #716d66 !important;

    // background: #5e92f3 !important;
    // border-color: #5e92f3 !important;
  }

  &.btn.btn-primary:hover:not(.btn-active) {
    background-color: white !important;
    color: #5E92F3 !important;
    // color: #716d66 !important;

  }

  &.btn.btn-primary:active:not(.btn-active) {
    background-color: white !important;
    color: #5E92F3 !important;
    // color: #716d66 !important;

  }

  &.btn.btn-primary:focus:not(.btn-active) {
    background-color: white !important;
    color: #5E92F3 !important;
    // color: #716d66 !important;

  }

  &.btn.btn-primary {
    background-color: white !important;
  }
}

.flag-icon {
  border-radius: 0.2rem;
}
