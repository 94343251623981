.management-table {
  border-collapse: unset;

  & th {
    border-bottom-width: 0px !important;
    text-transform: uppercase;
  }

  & th,
  td {
    vertical-align: middle;
  }
}

.module-check {
  &:checked {
    background-color: #5e92f3 !important;
  }
}
