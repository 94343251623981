@import "./assets/sass/style.scss";
@import "./assets/sass/style.react.scss";
@import "./assets/sass/plugins.scss";
@import "~bootstrap/scss/bootstrap";

#root {
  height: 100vh;
  width: 100vw;
}

blockquote {
  color: $gray-800;
  margin: 0.5rem 0;

  & p {
    padding-left: 0.8rem;
    border-left: 2px solid #5e92f3;
  }
}

ol,
ul {
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  padding-left: 1.5rem;
}

.hfs-8 {
  h1 {
    font-size: $font-size-base * 1.65;
  }

  h2 {
    font-size: $font-size-base * 1.4;
  }

  h3 {
    font-size: $font-size-base * 1.25;
  }

  h4 {
    font-size: $font-size-base * 1.15;
  }

  h5 {
    font-size: $font-size-base * 1.09;
  }

  h6 {
    font-size: $font-size-base * 1.05;
  }
}

.hfs-7 {
  h1 {
    font-size: $font-size-base * 1.7;
  }

  h2 {
    font-size: $font-size-base * 1.45;
  }

  h3 {
    font-size: $font-size-base * 1.3;
  }

  h4 {
    font-size: $font-size-base * 1.2;
  }

  h5 {
    font-size: $font-size-base * 1.12;
  }

  h6 {
    font-size: $font-size-base * 1.065;
  }
}

.hfs-6 {
  h1 {
    font-size: $font-size-base * 1.8;
  }

  h2 {
    font-size: $font-size-base * 1.55;
  }

  h3 {
    font-size: $font-size-base * 1.4;
  }

  h4 {
    font-size: $font-size-base * 1.3;
  }

  h5 {
    font-size: $font-size-base * 1.2;
  }

  h6 {
    font-size: $font-size-base * 1.085;
  }
}

.hfs-5 {
  h1 {
    font-size: $font-size-base * 1.85;
  }

  h2 {
    font-size: $font-size-base * 1.6;
  }

  h3 {
    font-size: $font-size-base * 1.45;
  }

  h4 {
    font-size: $font-size-base * 1.35;
  }

  h5 {
    font-size: $font-size-base * 1.25;
  }

  h6 {
    font-size: $font-size-base * 1.095;
  }
}

.hfs-4 {
  h1 {
    font-size: $font-size-base * 1.9;
  }

  h2 {
    font-size: $font-size-base * 1.65;
  }

  h3 {
    font-size: $font-size-base * 1.5;
  }

  h4 {
    font-size: $font-size-base * 1.4;
  }

  h5 {
    font-size: $font-size-base * 1.3;
  }

  h6 {
    font-size: $font-size-base * 1.2;
  }
}

.hfs-3 {
  h1 {
    font-size: $font-size-base * 1.95;
  }

  h2 {
    font-size: $font-size-base * 1.7;
  }

  h3 {
    font-size: $font-size-base * 1.55;
  }

  h4 {
    font-size: $font-size-base * 1.45;
  }

  h5 {
    font-size: $font-size-base * 1.35;
  }

  h6 {
    font-size: $font-size-base * 1.3;
  }
}
