.login-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.login-extra-background {
  filter: blur(9px);
  height: 100%;
  width: 100%;
}

.login-background {
  position: absolute;
}

.login-floater {
  position: absolute;
  top: 0;
  left: 2vw;
  z-index: 10;
  width: 500px;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;

  // background-color: transparent !important;
}

.login-mngmnt-btn {
  position: absolute;
  right: 2vw;
  top: 20px;
  z-index: 10;
}

.btn-management {
  background-color: #00a3df;
  color: white;

  &:hover {
    background-color: #048cbe !important;
    color: white;
  }
}

.character-img {
  max-height: 120px;
}

.login-banner {
  position: absolute;
  top: 0;
  right: 0;
  height: 8rem;
}

.login-form-inline {
  width: unset;
  flex-grow: 1;
}

.login-form-continue {
  width: 130px;
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
