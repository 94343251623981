.section {
  margin: 8px 10px;
  padding: 15px;
  // padding-left: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 1rem;

  p {
    margin-bottom: 0 !important;
  }
}

.disclaimer {
  margin: 15px 5px 15px 15px;
  // padding: 15px;
}

.tagged-text-tag {
  position: absolute;
  top: -5px;
  background: white;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  border: 1px solid;
}

.active-view-switch {
  background-color: #f5f5f5;
  color: #626262;
  height: 100%;
  text-transform: uppercase;
  border-top: 1px solid #d8d8d8;
  font-weight: 500;
}

.inactive-view-switch {
  background-color: #263238;
  color: #bec1c3;
  font-weight: 500;
  height: 100%;
  text-transform: uppercase;
  &:hover {
    cursor: pointer;
  }
}

.controls-toggle-btn {
  background-color: #c7c7c7 !important;
  color: white !important;
  padding: 0px !important;
  border: 1px solid #c7c7c7 !important;
  &:hover {
    background-color: #5e92f3 !important;
    background: #5e92f3 !important;
    border-color: #5e92f3 !important;
  }

  &.btn.btn-primary:hover:not(.btn-active) {
    background-color: #5e92f3 !important;
  }

  &.btn.btn-primary:active:not(.btn-active) {
    background-color: #5e92f3 !important;
  }

  &.btn.btn-primary:focus:not(.btn-active) {
    background-color: #5e92f3 !important;
    border-color: #5e92f3 !important;
  }

  &.btn.btn-primary {
    background-color: #c7c7c7 !important;
  }
}

.radio-response-input {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  margin-top: 8px;
  margin-bottom: 4px;

  input {
    &:hover {
      cursor: pointer;
    }
  }
}

.radio-response-title {
  color: #78350f;
  font-weight: 500;
}

.radio-response-description {
  margin-block: 8px;
  background-color: #dbeafe;
  border: 1px solid #60a5fa;
  color: #1e3a8a;
  border-radius: 8px;
  padding: 6px;
}
